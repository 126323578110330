import Beagle from 'vendor/beagle'
import ItemDialog from 'mape/item_dialog'

const CONFIG = {
	META_TAG_PREFIX: 'app'
};

const Mape = {

	/**
	 * App settings and library configuration constants.
	 *
	 * @access   public
	 * @property {Object} SETTINGS
	 */
	SETTINGS: Object.freeze({
		COLORS: {
			PRIMARY: '#4285f4',
			SECONDARY: '#cccccc',
			INFO: '#6ba4ff',
			SUCCESS: '#34a853',
			WARNING: '#fbbc05',
			DANGER: '#ea4335',
			DARK: '3d3c3c',
			LIGHT: '#ffffff'
		},

		AUTONUMERIC: {
			allowDecimalPadding: false, // @NOTE: this will fire a warning along `decimalPlaces`, but there's no workaround
			currencySymbol: '$ ',
			decimalCharacter: ',',
			decimalCharacterAlternative: '.',
			decimalPlaces: 0,
			digitGroupSeparator: '.',
			emptyInputBehaviour: 'zero',
			maximumValue: 9223372036854775807,
			minimumValue: 0,
			modifyValueOnWheel: false,
			unformatOnSubmit: true
		},

		MOMENT_FORMAT: 'DD/MM/YYYY',

		PLACES_AUTOCOMPLETE: {
			fields: ['place_id', 'name', 'types', 'geometry.location', 'geometry.viewport', 'formatted_address'],
			types: ['geocode', 'establishment']
		},

		DATATABLES: {
			language: {
				"sProcessing":     "Procesando...",
				"sLengthMenu":     "Mostrar _MENU_ registros",
				"sZeroRecords":    "<span class='d-block p-6 text-muted'>No se encontraron resultados.</span>",
				"sEmptyTable":     "<span class='d-block p-6 text-muted'>No hay información para mostrar.</span>",
				"sInfo":           "Mostrando registros <b>_START_ - _END_</b> de <b>_TOTAL_</b>",
				"sInfoEmpty":      "Mostrando registros <b>0 - 0</b> de <b>0</b>",
				"sInfoFiltered":   "(filtrado de un total de <b>_MAX_</b> registros)",
				"sInfoPostFix":    "",
				"sSearch":         "Buscar:",
				"sUrl":            "",
				"sInfoThousands":  ",",
				"sLoadingRecords": "Cargando...",
				"oPaginate": {
					"sFirst":    "<i class='icon mdi mdi-chevron-left'></i><i class='icon mdi mdi-chevron-left'></i>",
					"sLast":     "<i class='icon mdi mdi-chevron-right'></i><i class='icon mdi mdi-chevron-right'></i>",
					"sNext":     "<i class='icon mdi mdi-chevron-right'></i>",
					"sPrevious": "<i class='icon mdi mdi-chevron-left'></i>"
					},
				"oAria": {
					"sSortAscending":  ": Activar para ordenar la columna de manera ascendente",
					"sSortDescending": ": Activar para ordenar la columna de manera descendente"
					}
			}
		}
	}),

	/**
	 * Returns bytes number as a readable human size.
	 * @link   https://stackoverflow.com/a/18650828
	 * @param  {Integer} bytes - The size in *bytes*
	 * @return {String}
	 */
	bytesToSize: function (bytes) {
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes == 0) return '0 Byte';
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
	},

	/**
	 * Simple string-to-symbol number comparison.
	 * @param  {Number} left_num
	 * @param  {String} comparison - A symbol to compare `left_num`` against `right_num`
	 * @param  {Number} right_num
	 * @return {Boolean}
	 */
	compareNumbers: function (left_num, comparison, right_num) {
		let result;
		switch (comparison) {
			case '==':
				result = left_num === right_num;
				break;
			case '>':
				result = left_num > right_num;
				break;
			case '<':
				result = left_num < right_num;
				break;
			case '>=':
				result = left_num >= right_num;
				break;
			case '<=':
				result = left_num <= right_num;
				break;
			default:
				console.error(`Undefined comparison: ${comparison}`);
		}

		return result
	},

	/**
	 * @return {Boolean} true if Google Maps library is defined i.e. has successfully loaded.
	 */
	gmapsLibLoaded: function () {
		return typeof google != 'undefined'
	},

	/**
	 * Sets and renders a Google StreetViewPanorama instance.
	 *
	 * @link   https://developers.google.com/maps/documentation/javascript/reference#StreetViewPanorama
	 * @param  {String} target_id - The target container's id.
	 * @param  {Object} opts      - StreetViewPanorama opts (optional).
	 * @return {google.maps.StreetViewPanorama}
	 */
	createStreetViewPanorama: function (target_id, opts) {
		if ( !this.gmapsLibLoaded() ) {
			console.error('Missing Google Maps library');

		} else {
			var element  = document.getElementById(target_id),
					opts     = Object.assign({
						disableDefaultUI:      true,
						addressControl:        true,
						addressControlOptions: { position: google.maps.ControlPosition.TOP_LEFT },
						panControl:            true,
						panControlOptions:     { position: google.maps.ControlPosition.RIGHT_BOTTOM },
						zoomControl:           true,
						zoomControlOptions:    { position: google.maps.ControlPosition.TOP_RIGHT },
						showRoadLabels:        true
					}, opts),
					panorama = new google.maps.StreetViewPanorama(element, opts);

			return panorama;
		}
	},

	/**
	 * Analize inputs field is not empty
	 * @param  {Array} array_inputs - array with ids of inputs to analize.
	 * @return {Boolean}
	 */
	validateEmptyFields: function (array_inputs) {
		let validate_tags = new Array(array_inputs.length).fill(false);

		$.each(array_inputs, function(index, input) {
			const $elem = $(input)[0]
			if ($elem.value == '') {
				$elem.classList.add('border-danger')
				validate_tags[index] = true
			} else {
				$elem.classList.remove('border-danger')
				validate_tags[index] = false
			}
		});

		if (validate_tags.some(function(result) {return result} )) {
			return true
		} else {
			return false
		}
	},

	/**
	 * Select first option of Places automplete
	 * @param  {Object} input - select input for place autocomplete.
	 */
	enableEnterOnPAC: function (input) {
		/* Store original event listener */
		const _addEventListener = input.addEventListener

		const addEventListenerWrapper = (type, listener) => {
			if (type === 'keydown') {
				// store existing listener function
				const _listener = listener
				listener = (event) => {
					// simulate a 'down arrow' keypress if no address has been selected
					const suggestionSelected = document.getElementsByClassName('pac-item-selected').length
					if (event.key === 'Enter' && !suggestionSelected) {
						const e = new KeyboardEvent('keydown', {
							key: 'ArrowDown',
							code: 'ArrowDown',
							keyCode: 40,
						});
						_listener.apply(input, [e])
					}
					_listener.apply(input, [event])
				}
			}
			_addEventListener.apply(input, [type, listener])
		}
		input.addEventListener = addEventListenerWrapper
	},

	/**
	 * Formats numeric value into a delimited currency string for the current country.
	 *
	 * @param  {Number}
	 * @return {String}
	 */
	numberToCurrency: function (number) {
		return `$ ${ (number || 0).toLocaleString(this.COUNTRY.locale) }`
	},

	/**
	 * Add delimiters to an integer number
	 *
	 * @param  {Integer} integer
	 * @return {String}
	 */
	parseNumberDelimiters: function (integer) {
		return integer.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
	},

	/**
	 * Triggers default error popup
	 * @param {String}
	 */
	simpleErrorPopup: function (message) {
		Swal({
			title: 'Ha ocurrido un error',
			html: message,
			customClass: 'modal-full-color modal-full-color-danger',
			showCloseButton: true
		});
	},

	/**
	 * Sets the item filter js logic
	 * @listens 'filter:success'
	 */
	setItemFilter: function () {
		var form   = document.getElementById('item-filter'),
				target = document.getElementById('item-filter-results');
		var progressBar = new Mprogress({ template: 3 });

		var $moreFilters = $('.more-filters', form).hide();
		var $newFiltersOpen = $('#all-filter', form);

		$newFiltersOpen.click(function (e) {
			e.preventDefault();
			if (e.currentTarget.dataset.active == "false") {
				$moreFilters.slideDown(250, function () {
					e.currentTarget.dataset.active = true;
					e.currentTarget.innerHTML = '<i class="icon mdi mdi-caret-up mdi-hc-fw"></i> Ver menos filtros';
				});
			} else {
				$moreFilters.slideUp(250, function () {
					e.currentTarget.dataset.active = false;
					e.currentTarget.innerHTML = '<i class="icon mdi mdi-caret-down mdi-hc-fw"></i> Ver más filtros';
				});
			}
		});

		// init google places autocomplete
		var locationFields = form.querySelectorAll('input[name*="[near_location]"]');

		if ( this.gmapsLibLoaded() ) {
			Object.assign(this.SETTINGS.PLACES_AUTOCOMPLETE, { componentRestrictions: { country: Mape.COUNTRY.code } })
			var autocomplete   = new google.maps.places.Autocomplete( locationFields[0], this.SETTINGS.PLACES_AUTOCOMPLETE );

			// update coord inputs based on selected place
			autocomplete.addListener('place_changed', function () {
				var _coords = this.getPlace().geometry.location;
				locationFields[1].value = _coords.lat();
				locationFields[2].value = _coords.lng();
			});
		} else {
			locationFields.forEach(function (input) { input.disabled = true; });
		}

		// remove coord input values when deleting place
		locationFields[0].addEventListener('change', function (e) {
			if ( e.target.value.length == 0 ) {
				locationFields[1].value = null;
				locationFields[2].value = null;
				locationFields[1].removeAttribute('value');
				locationFields[2].removeAttribute('value');
			}
		});

		// reset location and coord inputs
		var changeEvt = new Event('change');
		locationFields[0].parentElement.querySelector('.remove-btn').addEventListener('click', function () {
			locationFields[0].value = null;
			locationFields[0].dispatchEvent(changeEvt);
		});

		let $startsAt = $('.datepicker[name*="[starts_at]"]', form);
		let $endsAt   = $('.datepicker[name*="[ends_at]"]'  , form);

		// Init DatePickers
		let momentFormat = this.SETTINGS.MOMENT_FORMAT;

		$endsAt.Zebra_DatePicker({ direction: true });

		$startsAt.Zebra_DatePicker({
			pair: $endsAt,
			onSelect: function() {
				var date = moment( this.val(), momentFormat).startOf('day');
				var endDate = date.clone().add(1, 'month').subtract(1, 'day').format(momentFormat);
				$endsAt.val(endDate).data('Zebra_DatePicker').show();
				$endsAt.trigger('change');

			}
		});

		// set filter dates values at input
		var search_params = new URLSearchParams(window.location.search)
		var start_date = search_params.get('f[within_dates][starts_at]');
		var end_date = search_params.get('f[within_dates][ends_at]');

		$startsAt.data('Zebra_DatePicker').set_date((start_date)) ;
		$endsAt.data('Zebra_DatePicker').set_date((end_date));

		// init autonumeric on price fields
		var price_fields_exist = !!form.querySelector('#f_within_prices_min');
		if ( price_fields_exist ) {
			var priceFields = new AutoNumeric('#f_within_prices_min', this.SETTINGS.AUTONUMERIC);
			priceFields.init('#f_within_prices_max');
		}

		// set form submit logic
		form.addEventListener('ajax:before', function (e) {
			// show loading icon and mask
			target.querySelector('div.be-loading').classList.add('be-loading-active');

			// start progress bar
			progressBar.start();

			// reformat price fields shortly after submission
			if ( price_fields_exist ) setTimeout(function () { priceFields.global.reformat(); }, 100);
		});

		// update target html with results on success
		form.addEventListener('filter:success', function (e) {
			target.innerHTML = e.detail.resultHTML;
			// reset popovers
			$('[data-toggle="popover"]', target).popover();
		});

		// show notification on error
		form.addEventListener('ajax:error', function (e) {
			Swal({
				title: 'Ha ocurrido un error',
				text: e.detail[1],
				customClass: 'modal-full-color modal-full-color-danger',
				showCloseButton: true
			});
		});

		// set the progress bar to complete on response
		form.addEventListener('ajax:complete', function () {
			progressBar.end();
		}.bind(this));

	},

	/**
	 * Transforms all periods into commas when pasting from the clipboard onto the target input.
	 * e.g. 12.05 -> 12,05
	 * @link https://github.com/autoNumeric/autoNumeric/issues/424#issuecomment-421770741
	 * @param {Object} input - The target input
	 */
	setAutonumericDecimalPaster: function (input) {
		if (input && input.length > 0) {
			input.bind('paste', function(e) {
				e.preventDefault();

				var clipboardData = (e.originalEvent || e).clipboardData || window.clipboardData;
				var pastedText    = undefined;

				if (window.clipboardData && window.clipboardData.getData) { // IE
					pastedText = window.clipboardData.getData('Text');

				} else {
					if (clipboardData && clipboardData.getData) {
						pastedText = clipboardData.getData('text/plain');
					}
				}

				if (pastedText != undefined) {
					pastedText    = pastedText.replace(Mape.SETTINGS.AUTONUMERIC.decimalCharacterAlternative, Mape.SETTINGS.AUTONUMERIC.decimalCharacter);
					var triggerAn = AutoNumeric.getAutoNumericElement(input[0]);
					triggerAn.set(pastedText);

					return false;
				}
			});
		};
	},

	/**
	 * Sets generic boilerplate event listeners to simple filterrific forms
	 * i.e. Progress bar, loading icon, tooltip reset, error dialog, etc.
	 * @param {Object} finders
	 *   @option {String} form_id   - id of form element.
	 *   @option {String} target_id - id of results container element.
	 */
	setFilterrificResponse: function (finders) {
		var finders = Object.assign({ form_id: 'filterrific_filter', target_id: 'filter-results' }, finders);
		var form    = document.getElementById(finders.form_id);
		var target  = document.getElementById(finders.target_id);
		var progressBar = new Mprogress({ template: 3 });

		// set form submit logic
		form.addEventListener('ajax:before', function (e) {
			progressBar.start();
			document.getElementById('filter-results').querySelector('div.be-loading').classList.add('be-loading-active');
		});

		// show notification on error
		form.addEventListener('ajax:error', function (e) {
			Swal({
				title: 'Ha ocurrido un error',
				text: e.detail[1],
				customClass: 'modal-full-color modal-full-color-danger',
				showCloseButton: true
			});
		});

		// reset tooltips on success
		form.addEventListener('ajax:success', function (e) {
			$('[data-toggle="tooltip"]', target).tooltip();
			$('[data-toggle="popover"]', target).popover();
		});

		// set the progress bar to complete on response
		form.addEventListener('ajax:complete', function () {
			progressBar.end();
		});
	},

	/**
	 * Proper JS constructor inheritance (ES5).
	 *
	 * @link  http://bolinfest.com/essays/googbase.html
	 * @param {Class} childCtor
	 * @param {Class} parentCtor
	 */
	setInheritance: function (childCtor, parentCtor) {
		function tempCtor() {};
		tempCtor.prototype = parentCtor.prototype;
		childCtor.superClass_ = parentCtor.prototype;
		childCtor.prototype = new tempCtor();
		childCtor.prototype.constructor = childCtor;
	},

	/**
	* Validate password field with the minimum validates:
	* @param[String] password_validation_class - container password fields
	*/
	setPasswordValidation: function(password_validation_class){
		var $parent = $(password_validation_class);
		var $passwordMessage = $parent.find('.message-password');
		var $passwordInput = $parent.find('.password');
		var $passwordConfirmationInput = $parent.find('.password-confirmation');

		$('#user_password, #user_password_confirmation').blur( function(){

			var rules = [
				$passwordInput.val() != $passwordConfirmationInput.val(),
				($passwordInput.val().length < 8 && $passwordInput.val().length > 0),
				($passwordConfirmationInput.val().length < 8 && $passwordConfirmationInput.val().length > 0),
				!$passwordInput.val().match(/\d+/),
				!$passwordInput.val().match(/[A-Z]+/),
				!$passwordInput.val().match(/[a-z]+/),
				$passwordInput.val().length == 0 && $passwordConfirmationInput.val().length == 0
			]

			if ((rules[0] || rules[1] || rules[2] || rules[3] || rules[4] || rules[5]) && !rules[6] ) {
				$passwordInput.addClass('is-invalid');
				$passwordConfirmationInput.addClass('is-invalid');
				$passwordMessage.removeClass('text-muted').addClass('text-danger');
				var message;

				if (rules[0]) {
					message = 'Confirmación de contraseña no coincide.<br/>';
				} else {
					message = 'Debe contener al menos 8 caracteres, un dígito, un carácter en mayúscula y uno en minúscula.<br/>';
				}

				$passwordMessage.show();
				$passwordMessage.html(message);
			} else {
				$passwordInput.removeClass('is-invalid');
				$passwordConfirmationInput.removeClass('is-invalid');
				$passwordMessage.hide();
			}
		});
	},

	/**
	 * Converts a string into Camel Case.
	 *
	 * @link   https://gist.github.com/cawfree/c08c10f6f2e7b2c8d225d88b031a03ce
	 * @param  {String} string
	 * @return {String}
	 */
	toCamelCase: function (string) {
		return string.replace(/_([a-z])/g, (g) =>  g[1].toUpperCase());
	},

	/**
	 * Converts a string into Snake Case.
	 *
	 * @link   https://gist.github.com/cawfree/c08c10f6f2e7b2c8d225d88b031a03ce
	 * @param  {String} string
	 * @return {String}
	 */
	toSnakeCase: function (string) {
		return string.match(/([A-Z])/g).reduce(
			(str, c) => str.replace(new RegExp(c), '_' + c.toLowerCase()),
			string
		)
		.substring((e.slice(0, 1).match(/([A-Z])/g)) ? 1 : 0);
	},

	/**
	 * Number of days non-weekend days between the passed date and the offset.
	 *
	 * @param  {Integer} offset      - Number of days in the future. Defaults to today.
	 * @param  {Date}    target_date - Day from where the count begins.
	 * @return {Integer}
	 */
	weekdaysSince: function (offset, targetDate) {
		let startDate    = moment(targetDate).startOf('day');
		let minStartDate = startDate.clone();

		while ( offset > 0 ) {
			minStartDate.add(1, 'day');

			if ( ![6,7].includes(minStartDate.isoWeekday()) ) {
				offset -= 1
			}
		}

		return minStartDate.diff(startDate, 'days');
	}

}

// set app constants from document's meta tags
document.head.querySelectorAll(`meta[name^=${CONFIG.META_TAG_PREFIX}-]`).forEach((metaTag) => {
	let label = metaTag.getAttribute('name').replace(`${CONFIG.META_TAG_PREFIX}-`, '').toUpperCase();
	Mape[label] = Object.freeze( JSON.parse(metaTag.dataset.json) );
	metaTag.parentNode.removeChild(metaTag);
});

// set post DOM-loaded logic
// e.g. library configs and initializations
$(document).ready(function () {

	/**
	* @NOTE: Add all DOM-dependent logic here.
	*/

	const $document = $(this);

	// init Beagle's JS logic
	Beagle.init();


	/**
	 * Lib config overrides
	 */

	// set tooltip and popover's default boundary opts
	$.fn.tooltip.Constructor.Default.boundary  = 'window';
	$.fn.popover.Constructor.Default.boundary  = 'window';
	$.fn.dropdown.Constructor.Default.boundary = 'window';

	// Set moment locale es
	moment.locale('es');

	// extend Zebra_DatePicker defaults
	$.extend($.fn.Zebra_DatePicker.defaults, {
		days: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
		direction: true,
		format: 'd/m/Y',
		icon_position: 'left',
		lang_clear_date: 'Limpiar',
		months: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
		offset: [-100, 37],
		open_on_focus: true,
		default_position: 'below',
		show_select_today: 'Hoy',
		show_icon: false,
		view: 'days'
	});

	// // extend summernote defaults
	// $.extend($.summernote.options, {
	// 	disableDragAndDrop: true,
	// 	height: 150,
	// 	lang: 'es-ES',
	// 	toolbar: [
	// 		['style'],
	// 		['font', ['bold', 'italic', 'underline', 'clear']],
	// 		['link'],
	// 		['para', ['ul', 'ol']]
	// 	],
	// 	styleTags: [
	// 		{ title: 'Título'   , tag: 'h4', value: 'h4' },
	// 		{ title: 'Subtítulo', tag: 'h5', value: 'h5' },
	// 		{ title: 'Párrafo'  , tag: 'p' , value: 'p'  },
	// 	],
	// 	callbacks: {
	// 		onPaste: function (e) {
	// 			var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
	// 			e.preventDefault();
	// 			document.execCommand('insertText', false, bufferText);
	// 		}
	// 	},
	// 	keyMap: {
	// 		mac: {
	// 			'CMD+B': 'bold',
	// 			'CMD+BACKSLASH': 'removeFormat',
	// 			'CMD+I': 'italic',
	// 			'CMD+K': 'linkDialog.show',
	// 			'CMD+SHIFT+NUM7': 'insertUnorderedList',
	// 			'CMD+SHIFT+NUM8': 'insertOrderedList',
	// 			'CMD+SHIFT+S': 'strikethrough',
	// 			'CMD+SHIFT+Z': 'redo',
	// 			'CMD+U': 'underline',
	// 			'CMD+Z': 'undo',
	// 			'ENTER': 'insertParagraph',
	// 			'TAB': 'tab'
	// 		},
	// 		pc: {
	// 			'CTRL+B': 'bold',
	// 			'CTRL+BACKSLASH': 'removeFormat',
	// 			'CTRL+I': 'italic',
	// 			'CTRL+K': 'linkDialog.show',
	// 			'CTRL+LEFTBRACKET': 'outdent',
	// 			'CTRL+SHIFT+NUM7': 'insertUnorderedList',
	// 			'CTRL+SHIFT+NUM8': 'insertOrderedList',
	// 			'CTRL+SHIFT+S': 'strikethrough',
	// 			'CTRL+U': 'underline',
	// 			'CTRL+Y': 'redo',
	// 			'CTRL+Z': 'undo',
	// 			'ENTER': 'insertParagraph',
	// 			'TAB': 'tab'
	// 		}
	// 	}
	// });

	// extend DataTable defaults
	$.fn.DataTable.ext.pager.numbers_length = 4
	$.extend(true, $.fn.dataTable.defaults, {
		dom: "<'row be-datatable-header'<'col-sm-6'l><'col-sm-6'f>><'row be-datatable-body'<'col-sm-12'tr>><'row be-datatable-footer'<'col-sm-5'i><'col-sm-7'p>>",
		ordering: false,
		language: Mape.SETTINGS.DATATABLES.language,
		fnDrawCallback: function(oSettings) {
					if (oSettings._iDisplayLength > oSettings.fnRecordsDisplay()) {
				$(oSettings.nTableWrapper).find('.be-datatable-footer').hide();
			}
		}
	})

	// extend Parsley defaults
	Parsley.options = Object.assign(Parsley.options, {
		errorClass: 'is-invalid',
		errorsMessagesDisabled: true,
		excluded: 'input[type=button], input[type=submit], input[type=reset], input[type=hidden], :hidden, [disabled], [readonly]:not(input.datepicker)'
	});


	/**
	 * Object instancing
	 */

	// init select2 instances
	$document.find('select.select2:not(.select2-custom)').select2({ width: '100%' });


	/**
	 * Event listeners
	 */

	// persist sidebar collapsed state
	$document.find('a.be-toggle-left-sidebar').click((e) => {
		e.preventDefault();

		if ( $('div.be-wrapper').hasClass('be-collapsible-sidebar-collapsed') ) {
			Cookies.set('sidebarCollapsed', 0, { expires: 365 });
		} else {
			Cookies.set('sidebarCollapsed', 1, { expires: 365 });
		}
	});

	// Bind "copy to clipboard" triggers.
	// @WARN: Clipboard API is not available in "unsafe" sites e.g. lvh.me.
	$document.on('click', '[data-clipboard]', (e) => {
		e.preventDefault();

		let text = e.currentTarget.dataset.clipboard;

		if (navigator.clipboard) {
			navigator.clipboard.writeText(text).then((result) => {
				iziToast.success({
					position: 'topRight',
					title: 'Copiado al portapapeles',
					timeout: 1000,
				});
			});

		} else {
			console.warn('Clipboard unavailable');
			console.info('Text to copy:', text);
		}
	});

	// override custom rails-ujs confirmation dialogs using Sweetalert2.
	$document.on('confirm', (e) => {
		e.preventDefault();
		let text = e.target.dataset.confirm;
		let color = e.target.dataset.color;

		if ( color === undefined ) { color = 'primary'; }

		Swal({
			title: '¡Atención!',
			html: text == 'true' ? 'Esta acción es irreversible. ¿Deseas continuar?' : text,
			focusConfirm: false,
			cancelButtonText: 'Cancelar',
			confirmButtonText: '<i class="icon mdi mdi-check mdi-hc-fw"></i> Continuar',
			customClass: 'modal-full-color modal-full-color-' + color,
			showCloseButton: true,
			showCancelButton: true,

		}).then(function (result) {
			if (result.value) {
				e.target.removeAttribute('data-confirm');
				e.target.click();
			}
		});
	});

	// bugfix for autofocus issues of jquery v3.6.x
	// @link https://github.com/select2/select2/issues/5993#issuecomment-802819789
	$document.on('select2:open', () => {
		document.querySelector('.select2-search__field').focus();
	});

	// disable form submission via 'enter' keypress on inputs
	$document.on(
		'keyup keydown keypress', 'form:not(.submit-on-enter, #filterrific_filter) :input:not(.submit-on-enter, textarea, button, a, [type="submit"], [type="file"])',
		e => e.keyCode != 13
	);

	// set file upload buttons
	$document.on('change', '.inputfile', (e) => {
		var input = e.currentTarget;
		var label = input.nextElementSibling.querySelector('span');


		// validates photo size based on predefined max file upload size
		if (input.files) {
			var size = input.files[0].size
			var maxSize = Mape.APP.maxUploadFileSize;
			if ($(input).hasClass('item-photo-file')) maxSize = Mape.APP.maxUploadImageSize;

			// @TODO: size by file (multiupload)
			if (size > maxSize) {
				iziToast.error({
					title: 'No es posible cargar la imagen',
					text: 'El tamaño de la imagen no debe ser mayor a ' + Mape.bytesToSize(maxSize),
					class_name: 'color danger'
				});

				// reset upload text
				label.innerHtml = 'Subir imagen';

				// clear the file input
				input.value = null;

			} else {
				var fileName = '';

				if ( input.files.length > 1 ) {
					fileName = ( input.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', input.files.length );
				} else if ( e.target.value ) {
					fileName = e.target.value.split( '\\' ).pop();
				}

				// set btn label
				label.innerHTML = fileName;
			}
		}
	});

	// set global item dialog trigger for elements with the `data-item-dialog` attribute
	// the value will set the item loaded
	// NOTE: the view must include google places library to load streetview
	$document.on('click', '[data-item-dialog]', (e) => {
		e.preventDefault();
		Mape.itemDialog = Mape.itemDialog || new ItemDialog();
		Mape.itemDialog.update( e.currentTarget.dataset.itemDialog );
	});

	// set auto error popup on rails ujs error events
	$document.on('ajax:error', '.ajax-error-popup', (xhr, error, errorText) => {
		Mape.simpleErrorPopup(errorText || 'El servidor no responde.');
	});

});

export default Mape
